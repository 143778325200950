import React, { useState } from 'react';
import './App.css';
import Header from './Header';
import Footer from './Footer';

function App() {
  const [answers, setAnswers] = useState({ turfType: '', zipCode: '' });
  const [currentQuestion, setCurrentQuestion] = useState(1);

  const handleInputChange = (e) => {
    setAnswers({ ...answers, [e.target.name]: e.target.value });
  };

  const handleNext = () => {
    setCurrentQuestion(currentQuestion + 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Submitted Answers:', answers);
    // Add your submission logic here
  };

  return (
    <div className="App">
      <Header />
      <form onSubmit={handleSubmit}>
        {/* Turf Type Question */}
        <div className={`question ${currentQuestion === 1 ? 'visible' : ''}`}>
          <fieldset>
            <legend>Hello Welcome to Landscape Genie! What type of Turf are you installing?</legend>
            <label>
              <input 
                type="radio" 
                name="turfType" 
                value="Recycled Turf" 
                onChange={handleInputChange} 
                checked={answers.turfType === 'Recycled Turf'} 
              />
              Recycled Turf
            </label>
            <label>
              <input 
                type="radio" 
                name="turfType" 
                value="New Turf" 
                onChange={handleInputChange} 
                checked={answers.turfType === 'New Turf'} 
              />
              New Turf
            </label>
            

          </fieldset>
          <button type="button" onClick={handleNext}>Next</button>
        </div>
        
        {/* ZIP Code Question */}
        <div className={`question ${currentQuestion === 2 ? 'visible' : ''}`}>
          <fieldset>
            <legend>What is your ZIP code?</legend>
            <input
              type="text"
              name="zipCode"
              value={answers.zipCode}
              onChange={handleInputChange}
              placeholder="Enter 5-digit ZIP"
              maxLength="5"
            />
          </fieldset>
          {/* Add more navigation or submission buttons as needed */}
        </div>

        {/* Add more questions as needed */}
      </form>
      <Footer />
    </div>
  );
}

export default App;

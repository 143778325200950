import React, { useState } from 'react';
import BurgerMenu from './BurgerMenu'; // Make sure to create this component
import SideMenu from './SideMenu'; // Make sure to create this component
import './Header.css';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="App-header">
      <a href="https://www.landscapegenie.ai">
        <img src="/logo.png" alt="Landscape Genie Logo" className="header-logo" />
      </a>
      <h1>Artificial Grass Installation Assistant</h1>
      <BurgerMenu onClick={toggleMenu} />
      <SideMenu isOpen={isMenuOpen} />
    </header>
  );
}

export default Header;

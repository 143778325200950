import React from 'react';
import './SideMenu.css';

function SideMenu({ isOpen }) {
  return (
    <div className={`side-menu ${isOpen ? 'open' : ''}`}>
      <img src="/logo.png" alt="Logo" className="side-menu-logo" />
      <ul className="side-menu-list">
        <li>About Us</li>
        <li>Contact Us</li>
        <li>Find a Licensed Professional</li>
      </ul>
    </div>
  );
}

export default SideMenu;
